import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";

function TradingViewWidget({ id, sym, width, height, gridName, ticker }) {
  let tvScriptLoadingPromise;
  const onLoadScriptRef = useRef();
  const [widgetCreated, setWidgetCreated] = useState(false); // Track whether the widget has been created

  function createWidget(i, s) {
    console.log(`sym: ${s}`);
    if (document.getElementById(i) && "TradingView" in window) {
      if (!window.TVC) window.TVC = {};
      window.TVC[gridName] = new window.TradingView.widget({
        width: "100%",
        height,
        symbol: s !== undefined ? s : "UNISWAP3ETH:RLBUSDT",
        interval: "D",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        allow_symbol_change: true,
        hide_side_toolbar: false,
        container_id: i,
      });

      return window.TVC[gridName];
    }
  }

  const cid = `tradingview_92c8${gridName}`;
  console.log(`cid: ${cid}`);
  const destroyExistingWidget = () => {
    const oldWidget =
      window.TradingView &&
      window.TradingView.globals &&
      window.TradingView.globals.widget;
    if (
      oldWidget &&
      oldWidget.options &&
      oldWidget.options.container_id === cid
    ) {
      oldWidget.remove();
    }
  };

  const getSymbol = (url) => {
    const urlSearchParams = new URLSearchParams(url);
    const symbolValue = urlSearchParams.get("symbol");

    // Using regular expression
    const regex = /symbol=([^&]+)/;
    const match = url.match(regex);
    const symbolValueUsingRegex = match ? match[1] : null;
    return symbolValue;
  };

  const getInterval = (url) => {
    const urlSearchParams = new URLSearchParams(url);
    const symbolValue = urlSearchParams.get("interval");

    // Using regular expression
    const regex = /symbol=([^&]+)/;
    const match = url.match(regex);
    const symbolValueUsingRegex = match ? match[1] : null;
    return symbolValue;
  };

  useEffect(() => {
    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }
    onLoadScriptRef.current = () => {
      if (!widgetCreated) {
        // destroyExistingWidget();
        const w = createWidget(cid, ticker);
        if (w) {
          console.log("**** WWWWW*** ");
          console.log(JSON.stringify(w));
          console.log(w);

          // window.TVC[gridName] = w;
          // window.WWWWW = w;
          setInterval(() => {
            // Get the widget's state
            // const widgetState = w.save('chart');

            // Store the widget's state in localStorage
            // localStorage.setItem(w.options.container, JSON.stringify(w));
            localStorage.setItem(
              `${gridName}-cell-config`,
              JSON.stringify({
                // "sym" : getSymbol(window.TVC[gridName].generateUrl()),
                // "interval": getInterval(window.TVC[gridName].generateUrl())
              }),
            );
            // console.log("url: " + window.TVC[gridName].generateUrl());
            // console.log("sym: " + getSymbol(window.TVC[gridName].generateUrl()));
          }, 10000);
          // localStorage.setItem(w.options.container, JSON.stringify(w));
        }
      }
    };

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current(),
    );

    return () => (onLoadScriptRef.current = null);
  }, [width, height]);

  return (
    <div className="tradingview-widget-container">
      <div id={cid} />
    </div>
  );
}

export default TradingViewWidget;
