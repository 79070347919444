import React, { useEffect } from "react";

function LiveCoinWatchWidget({ id, sym, width, height, gridName, ticker }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.livecoinwatch.com/static/lcw-widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const coin = ticker;
  const base = "USD";
  const period = "d";
  const colorTx = "#ffffff";
  const colorBg = "#1f2434";
  const borderWidth = "0";
  // Ensure lcw-* tags are passed correctly
  const lcwProps = {
    "lcw-coin": coin,
    "lcw-base": base,
    "lcw-period": period,
    "lcw-color-tx": colorTx,
    "lcw-color-bg": colorBg,
    "lcw-border-w": borderWidth,
  };

  // Create a ref using React's useRef Hook
  const widgetRef = React.useRef(null);

  // Assign the ref to the div in the return statement

  // Now you can access the div using widgetRef.current in your useEffect Hook
  useEffect(() => {
    if (widgetRef.current) {
      Object.keys(lcwProps).forEach((key) => {
        widgetRef.current.setAttribute(key, lcwProps[key]);
      });
    }
  }, [lcwProps]);

  // Add styles to make the div fill its container
  const style = {
    width: "100%",
    height: "100%",
  };

  return (
    <div
      style={{
        transform: "scale(0.70)",
        position: "absolute",
        top: 6,
        left: -45,
        margin: 0,
        padding: 0,
      }}
    >
      <div
        id={gridName}
        className="livecoinwatch-widget-6"
        ref={widgetRef}
        style={{ width: "100%", margin: 0, padding: 0 }}
      />
    </div>
  );
}

export default LiveCoinWatchWidget;
