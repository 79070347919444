// Web3AccountContext.js
import { createContext, useContext, useState } from "react";

const Web3AccountContext = createContext();

export const Web3AccountProvider = ({ children }) => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);

  const setWeb3State = (newWeb3) => {
    setWeb3(newWeb3);
  };

  const setAccountState = (newAccount) => {
    setAccount(newAccount);
  };

  const contextValue = {
    web3,
    account,
    setWeb3State,
    setAccountState,
  };

  return (
    <Web3AccountContext.Provider value={contextValue}>
      {children}
    </Web3AccountContext.Provider>
  );
};

export const useWeb3Account = () => {
  return useContext(Web3AccountContext);
};
