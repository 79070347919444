import React from "react";

function Roadmap() {
  return (
    <div style={{ padding: "0 40px" }}>
      <h2>Roadmap</h2>
      <p>Here's the roadmap for the future development of Bitference:</p>
      <ul>
        <li>Enhance user experience with a more intuitive dashboard layout</li>
        <li>Add support for more cryptocurrency exchanges</li>
        <li>Integrate real-time news and market analysis</li>
        <li>Expand charting options and indicators</li>
        <li>Implement social sharing features</li>
        <li>Adding more custom widgets for monitoring various market data</li>
        <li>Introducing new action-oriented types of widgets</li>
        <li>Implement saving and loading of dashboards</li> {/* New feature */}
      </ul>

      <h3>Known Issues</h3>
      <p>
        While we strive to provide a seamless experience, there are a few known
        issues:
      </p>
      <ul>
        <li>
          Chart, indicators, and custom drawings are not saved due to licensing
          restrictions. We are actively working to address this limitation.
          Donations and special donations are welcome and will help expedite the
          solution.
        </li>
        <li>
          Each dashboard is currently limited to creating up to 20
          widgets/items. This limitation will be revised and increased in the
          near future once license-related issues are resolved or our own
          charting backend is introduced.
        </li>
      </ul>
    </div>
  );
}

export default Roadmap;
