import React from "react";

function About() {
  return (
    <div style={{ padding: "0 40px" }}>
      <h2>About Bitference</h2>
      <p>
        Bitference is a versatile financial and cryptocurrency dashboard that
        empowers users to craft personalized charts using a range of widgets.
        This platform offers traders and users a comprehensive space to observe,
        track, and engage with the markets that matter most to them.
      </p>
      {/* You can add more content about Bitference here */}
    </div>
  );
}

export default About;
