import React from "react";

function DonationLink() {
  const donationAddress = "0xA02c23ba0d7eb911E8C2E91a9b351CeCC1889aD0";

  return (
    <p>
      Donation:{" "}
      <a
        href={`https://etherscan.io/address/${donationAddress}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white", fontWeight: "bold" }}
      >
        {donationAddress}
      </a>
    </p>
  );
}

export default DonationLink;
