import React from "react";

import TradingViewWidget from "./TV";
import TradingViewWidgetTicker3 from "./TVT3";
import TradingViewWidgetTicker4 from "./TVT4";
import CoinStatsWidget from "./widget/CoinStats-Ticker";
import LiveCoinWatchWidget from "./widget/LiveCoinWatchWidget-Ticker";

class GridItem extends React.Component {
  constructor(props) {
    super(props);
    // You can access props here using this.props
    this.itemRef = React.createRef();
    this.state = {
      hasMounted: false,
      tvWidth: this.props.tvWidth,
      tvHeight: this.props.tvHeight,
      ticker: this.props.ticker,
      gridName: this.props.gridName,
      type: this.props.type,
    };
    this.resizeObserver = null;

    console.log(`TICKER: ${this.props.ticker}`);
  }

  componentDidMount() {
    // This code runs once after the initial render, similar to useEffect(() => { ... }, [])
    if (this.itemRef.current) {
      // console.log('DOM element:', this.itemRef.current);
      const rect = this.itemRef.current.getBoundingClientRect();
      console.log("Width:", rect.width);
      console.log("Height:", rect.height);
      this.state.tvWidth = rect.width * 0.94;
      this.state.tvHeight = rect.height * 0.94;
    }

    this.setState({ hasMounted: true });

    // Set up the ResizeObserver
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        const { height } = entry.contentRect;
        console.log(`Width: ${width}, Height: ${height}`);
        this.state.tvWidth = width * 0.94;
        this.state.tvHeight = height * 0.94;
      }
    });

    // Start observing the element
    if (this.itemRef.current) {
      this.resizeObserver.observe(this.itemRef.current);
    }
  }

  componentWillUnmount() {
    // Disconnect the observer to prevent memory leaks
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    let widget;
    switch (this.state.type) {
      case "ticker":
        widget = (
          <TradingViewWidgetTicker3
            id={this.state.gridName}
            gridName={this.state.gridName}
            width={this.state.tvWidth}
            height={this.state.tvHeight}
            ticker={this.state.ticker}
          />
        );
        break;
      case "ticker2":
        widget = (
          <CoinStatsWidget
            id={this.state.gridName}
            gridName={this.state.gridName}
            width={this.state.tvWidth}
            height={this.state.tvHeight}
            ticker={this.state.ticker}
          />
        );
        break;
      case "ticker3":
        widget = (
          <LiveCoinWatchWidget
            id={this.state.gridName}
            gridName={this.state.gridName}
            width={this.state.tvWidth}
            height={this.state.tvHeight}
            ticker={this.state.ticker}
          />
        );
        break;
      default:
        widget = (
          <TradingViewWidget
            id={this.state.gridName}
            gridName={this.state.gridName}
            width={this.state.tvWidth}
            height={this.state.tvHeight}
            ticker={this.state.ticker}
          />
        );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={this.itemRef}
      >
        {this.state.hasMounted && true && widget}
      </div>
    );
  }
}

export default GridItem;
