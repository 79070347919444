import React, { useState } from "react";
import VirtualizedSelect from "react-select-virtualized";

function LargeJsonSearch({
  jsonData,
  onAddChart,
  onAddTicker,
  onAddTicker2,
  onAddTicker3,
  selectOptions,
  dataSymbolsNames,
}) {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  let options;
  if (selectOptions === undefined) {
    options = jsonData.map((item) => ({
      value: item,
      label: item,
    }));
  } else {
    options = selectOptions;
  }

  const handleAddChart = () => {
    console.log(`Type of ${JSON.stringify(selectedOption)}`);
    if (selectedOption.value.trim() !== "") {
      onAddChart(selectedOption.value);
      // setSelectedPair(null);
    }
  };

  const handleAddTicker = () => {
    console.log("handleAddTicker called in json search");
    if (selectedOption.value.trim() !== "") {
      onAddTicker(selectedOption.value);
      // setSelectedPair(null);
    }
  };

  const handleAddTicker2 = () => {
    console.log("handleAddTicker2 called in json search");
    if (selectedOption.value.trim() !== "") {
      console.log(`ticker2 value: ${selectedOption.value}`);

      onAddTicker2(selectedOption.value);
      // setSelectedPair(null);
    }
  };

  const handleAddTicker3 = () => {
    console.log("handleAddTicker3 called in json search");
    if (selectedOption.value.trim() !== "") {
      console.log(`ticker2 value: ${selectedOption.value}`);

      onAddTicker3(selectedOption.value);
      // setSelectedPair(null);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 200,
    }),
    menu: (provided) => ({
      ...provided,
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "blue" : "white",
    }),
    control: (provided) => ({
      ...provided,
      width: fixedWidth, // Set a fixed width for the control
    }),
  };

  const longestOptionLabel = options.reduce(
    (longest, option) =>
      option.label.length > longest.length ? option.label : longest,
    "",
  );

  // Set a fixed width based on the longest option label
  const fixedWidth = `${longestOptionLabel.length * 5}px`; // Adjust the factor as needed

  return (
    <div>
      <VirtualizedSelect
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        searchable
        placeholder="Pair"
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPlacement="auto"
      />

      {true && (
        <div>
          <p />
          <div className="button-row">
            <button className="smaller-button" onClick={handleAddChart}>
              Add Chart
            </button>
            &nbsp;&nbsp;
            <button className="smaller-button" onClick={handleAddTicker}>
              Add Ticker (TV)
            </button>
          </div>
          <p />
          <div className="button-row">
            <button className="smaller-button" onClick={handleAddTicker2}>
              Add Ticker (CS)
            </button>
            &nbsp;&nbsp;
            <button className="smaller-button" onClick={handleAddTicker3}>
              Add Ticker (LCS)
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LargeJsonSearch;
