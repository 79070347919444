import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";

function TradingViewWidgetTicker3({
  id,
  sym,
  width,
  height,
  gridName,
  ticker,
}) {
  let tvScriptLoadingPromise;

  const onLoadScriptRef = useRef();
  const cid = `tradingview_92c8${gridName}`;
  console.log(`Ticer: ${width}`);
  function createWidget(i, s) {
    console.log(`sym: ${s}`);
    if (document.getElementById(i) && "TradingView" in window) {
      new window.TradingView.widget({
        symbol: ticker !== undefined ? ticker : "BITSTAMP:BTCUSD",
        width: "100%",
        height: parseInt(height),
        colorTheme: "dark",
        isTransparent: false,
        locale: "en",
        allow_symbol_change: true,
        container_id: 1,
      });
    }
  }

  function loadTradingViewScript(ticker, width, height, cid) {
    tvScriptLoadingPromise = new Promise((resolve) => {
      console.log(`TICKER: ${ticker}`);
      const script = document.createElement("script");
      script.async = true;
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js";
      script.type = "text/javascript";
      script.innerHTML = JSON.stringify({
        symbol: ticker,
        width: "100%",
        height: parseInt(height),
        colorTheme: "dark",
        isTransparent: false,
        locale: "en",
        container_id: cid,
      });
      script.onload = resolve;

      // if(!document.querySelector("#" + cid))
      document.querySelector(`#${cid}`).appendChild(script);
    });

    return tvScriptLoadingPromise;
  }

  const destroyExistingWidget = (cid) => {
    const containerElement = document.getElementById(cid);
    if (containerElement) {
      while (containerElement.firstChild) {
        containerElement.removeChild(containerElement.firstChild);
      }
    }
  };

  useEffect(() => {
    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = loadTradingViewScript(
        ticker,
        width,
        height,
        cid,
      );
    }
    onLoadScriptRef.current = () => {
      destroyExistingWidget(cid);
      tvScriptLoadingPromise = loadTradingViewScript(
        ticker,
        width,
        height,
        cid,
      );
    };

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current(),
    );

    return () => (onLoadScriptRef.current = null);
  }, [width, height, ticker, cid]);

  return (
    <div
      className="tradingview-widget-container"
      style={{ height: { height } }}
    >
      <div
        className="tradingview-widget-container__widget"
        id={cid}
        style={{ height: { height } }}
      />
    </div>
  );
}

export default TradingViewWidgetTicker3;
