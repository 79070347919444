import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga";
import Ad from "react-adsense";
import MetamaskLogin from "./MM";
import TradingViewWidget from "./TV";
import TradingViewWidgetTicker3 from "./TVT3";
import AddContainerButton from "./AddChartButton";
import AddTickerButton from "./AddTickerButton";
import "react-toastify/dist/ReactToastify.css";
import logo_bf from "./logo-bf.png";
import AddDashboardButton from "./AddDashboard";
import DonationLink from "./Donation";

import SeoComponent from "./SEO";
import AddContainerButtonDex from "./AddChartButtonDex";
import AddContainerButtonDex2 from "./AddChartButtonDex2";
import AddTickerButtonDex from "./AddTickerButtonDex";
import AdsComponent from "./Ads";
import { FeedbackFish } from "@feedback-fish/react";
import { SHA256 } from "crypto-js";
import LoadDashboard from "./LoadDashboard";
import LargeJsonSearch from "./JsonSearch";
import LargeJsonSearchDex from "./JsonSearchDex";
import { useWeb3Account } from "./Web3AccountContext";

function DashboardControls({
  //handleSaveDashboard,
  handleClearAll,
  handleResetUUID,
  handleLockDashboard,
  handleUnlockDashboard,
  handleAddContainer,
  handleAddTicker,
  handleAddTicker2,
  handleAddTicker3,
  dataSymbolsNames,
  //dataSymbols,
  dataLayouts,
  dataItemConfigs,
  dataUUID,
  dataDashboardName,
  dataCellIndex,
  dataNumContainers,
  updateLayouts,
  updateItemConfigs,
  updateCellIndex,
  updateNumContainers,
  updateUUID,
  updateDashboardName,
}) {
  const [isMenuVisible, setMenuVisible] = useState(
    localStorage.getItem("isMenuVisible") === "true" || false,
  );

  useEffect(() => {
    localStorage.setItem("isMenuVisible", isMenuVisible);
  }, [isMenuVisible]);

  const toggleMenuVisibility = () => {
    setMenuVisible(!isMenuVisible);
  };

  const [symbols, setSymbols] = useState([]);
  const [tradingPairs, setTradingPairs] = useState([]);

  const [symbolsDex, setSymbolsDex] = useState([]);
  const [tradingPairsDex, setTradingPairsDex] = useState([]);
  const exchanges = [
    "BINANCE",
    "COINBASE",
    "KRAKEN",
    "GEMINI",
    "BITSTAMP",
    "BITFINEX",
    "CRYPTO"
  ]; // List of exchanges
  const currency = ["USD"]; // List of currencies

  const exchangesDex = ["UNISWAP3ETH"]; // List of exchanges
  const currencyDex = ["USDT", "WETH"]; // List of currencies

  const excludeListPerExchange = {
    BITSTAMP: ["BNB"],
    COINBASE: ["BNB"],
    KRAKEN: ["BNB"],
  };

  const ENV = "PROD";
  //let apiUrl;
  let apiUrl = process.env.REACT_APP_API_URL;
  /* switch (false) {
    case "DEV":
      apiUrl = "http://127.0.0.1:5000";
      break;
    case "STG":
      apiUrl = "http://202.182.102.126:80";
      break;
    case "PROD":
      apiUrl = "http://45.32.134.92:80";
      break;
    default:
      apiUrl = "http://127.0.0.1:5000";
  }*/

  useEffect(() => {
    fetch("./crypto_syms.json")
      .then((response) => response.json())
      .then((data) => {
        setSymbols(data.map((symbol) => symbol.toUpperCase()));
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  useEffect(() => {
    if (symbols.length > 0) {
      const generatedTradingPairs = generateTradingPairs();
      setTradingPairs(generatedTradingPairs);
    }
  }, [symbols]);

  useEffect(() => {
    fetch("./uniswap_tokens_sym.json")
      .then((response) => response.json())
      .then((data) => {
        setSymbolsDex(data.map((symbol) => symbol.toUpperCase()));
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  useEffect(() => {
    if (symbols.length > 0) {
      const generatedTradingPairs = generateTradingPairsDex();
      setTradingPairsDex(generatedTradingPairs);
    }
  }, [symbolsDex]);

  const signData2 = async (dataToSign) => {
    const { w3, acc } = useWeb3Account();
    if (w3 && acc) {
      try {
        let jsonStr = JSON.stringify(dataToSign, null, 2);
        const hash = SHA256(jsonStr).toString();
        const dataToSignHex = w3.utils.asciiToHex(hash);
        const msgParams = [acc, dataToSignHex];
        const signature = await w3.currentProvider.send(
          "personal_sign",
          msgParams,
        );
        return signature;
      } catch (error) {
        console.error("Error signing data:", error);
      }
    } else {
      console.error("Web3 instance or account not initialized.");
    }
  };

  const generateTradingPairsDex = () => {
    const tradingPairs = [];
    exchangesDex.forEach((exchange) => {
      symbols.forEach((symbol) => {
        if (
          !excludeListPerExchange[exchange] ||
          !excludeListPerExchange[exchange].includes(symbol)
        ) {
          currencyDex.forEach((cur) => {
            tradingPairs.push({
              label: `${symbol}:${cur}`,
              value: `${exchange}:${symbol}${cur}`,
            });
          });
        }
      });
    });
    return tradingPairs;
  };

  //CEX
  const generateTradingPairs = () => {
    const tradingPairs = [];
    exchanges.forEach((exchange) => {
      symbols.forEach((symbol) => {
        if (
          !excludeListPerExchange[exchange] ||
          !excludeListPerExchange[exchange].includes(symbol)
        ) {
          currency.forEach((cur) => {
            tradingPairs.push({
              label: `${exchange} - ${symbol}:${cur}`,
              value: `${exchange}:${symbol}${cur}`,
            });
          });
        }
      });
    });
    return tradingPairs;
  };

  const saveToServer2 = async (layouts, itemConfigs) => {
    const apiUrl = "http://127.0.0.1:5000/dashboard"; // Replace with your API URL

    try {
      // const dataToSave = dashboards;
      const accounts = ethereum.request({
        method: "eth_requestAccounts",
      });

      const d = {
        layouts: layouts,
        configs: itemConfigs,
      };
      console.log("sig: " + JSON.stringify(sign));

      const dataToSign = JSON.stringify(layouts);
      const signature = await signData2(d);

      const payload = {
        data: d,
        hash: dataHash,
        signature,
      };
      console.log("payload: ");
      console.log(payload);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Auth-PubKey": accounts[0],
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data saved to server:", responseData);
        // Optionally, perform any action you want after successful save
        toast.success("Data saved to server!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        console.error("Error saving data to server:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //DEX

  return (
    //              <AddDashboardButton onSaveDashboard={handleSaveDashboard} />
    <div>
      <button
        className={`button ${isMenuVisible ? "hide-button" : "show-button"}`}
        onClick={toggleMenuVisibility}
      >
        {isMenuVisible ? "Hide Menu" : "Show Menu"}
      </button>

      {isMenuVisible && (
        <div>
          <p />
          <div className="horizontal-buttons">
            <div className="button-spacing" />
          </div>

          <p />
          <div className="metamask-login">
            <MetamaskLogin
              dataLayouts={dataLayouts}
              dataItemConfigs={dataItemConfigs}
              dataUUID={dataUUID}
              dataCellIndex={dataCellIndex}
              dataNumContainers={dataNumContainers}
              updateLayouts={updateLayouts}
              updateItemConfigs={updateItemConfigs}
              updateCellIndex={updateCellIndex}
              updateNumContainers={updateNumContainers}
              updateUUID={updateUUID}
              updateDashboardName={updateDashboardName}
              dataAPIUrl={apiUrl}
            />
          </div>

          <div className="current-uuid">ID: {dataUUID}</div>
          <div className="current-dashboard-name">
            Name: {dataDashboardName}
          </div>

          <p />
          <div className="horizontal-buttons">
            Dashboard:
            <div className="button-spacing" />
            <button
              className="smaller-button"
              onClick={() => handleClearAll(true)}
            >
              Clear
            </button>
            <div className="button-spacing" />
            <button className="smaller-button" onClick={handleResetUUID}>
              New
            </button>
            <div className="button-spacing" />
            <button className="smaller-button" onClick={handleLockDashboard}>
              Lock
            </button>
            <div className="button-spacing" />
            <button className="smaller-button" onClick={handleUnlockDashboard}>
              Unlock
            </button>
          </div>

          <p />
          <p />
          <p />
          <p />
          <div className="horizontal-buttons">
            CEX:
            <div className="button-spacing" />
            <div className="button-spacing" />
            <LargeJsonSearch
              onAddChart={handleAddContainer}
              onAddTicker={handleAddTicker}
              onAddTicker2={handleAddTicker2}
              onAddTicker3={handleAddTicker3}
              selectOptions={tradingPairs}
              dataSymbolsNames={dataSymbolsNames}
            />
          </div>

          <p />

          <div className="horizontal-buttons">
            Uniswap V3:
            <div className="button-spacing" />
            <LargeJsonSearchDex
              onAddChart={handleAddContainer}
              onAddTicker={handleAddTicker}
              selectOptions={tradingPairsDex}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardControls;
