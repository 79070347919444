import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DonationLink from "./Donation";
import About from "./About";
import Roadmap from "./Roadmap";
import SpecialDonation from "./SpecialDonation";
import MetamaskLogin from "./MM";
import { Web3AccountProvider } from "./Web3AccountContext"; // Adjust the path

const scriptSrc = "https://www.googletagmanager.com/gtag/js?id=G-KKLR9QGZMG";

function Index() {
  return (
    <Router>
      <header className="header" style={{ paddingTop: "20px" }}>
        <div className="logo">
          <Link to="/">
            <img
              src="logo-bf.png"
              alt="Bitference"
              style={{ width: "200px", marginBottom: "20px" }} // Adjust the width and margin as needed
            />
          </Link>
        </div>
      </header>
      <Web3AccountProvider>
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/roadmap" component={Roadmap} />
          <Route path="/special-donation" component={SpecialDonation} />
          <Route path="/" component={App} /> {/* Default route */}
        </Switch>
      </Web3AccountProvider>
      <footer
        style={{
          marginTop: "20px",
          paddingBottom: "20px",
          textAlign: "center",
          color: "#888",
        }}
      >
        <p>&copy; 2023 Bitference. All rights reserved.</p>

        <p className="about-site" style={{ fontSize: "12px" }}>
          Bitference is a versatile financial and cryptocurrency dashboard that
          empowers users to craft personalized charts using a range of widgets.{" "}
          <br />
          This platform offers traders and users a comprehensive space to
          observe, track, and engage with the markets that matter most to them.
        </p>
        <nav className="footer-nav">
          <Link to="/about">About</Link> | <Link to="/roadmap">Roadmap</Link> |{" "}
          <Link to="/special-donation">Special Donation</Link> |{" "}
          <a href="https://twitter.com/bitference">Twitter</a> |{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://us21.list-manage.com/survey?u=cbde3032679c0c56b6b46ab09&id=10964488f9&attribution=false"
          >
            Feedback
          </a>
        </nav>

        <DonationLink />
      </footer>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Helmet>
      <title>
        Bitference - Financial and Cryptocurrency Personalized Dashboard
      </title>
      <meta
        name="description"
        content="Bitference is a versatile financial and cryptocurrency dashboard that empowers users to craft personalized charts using a range of widgets. This platform offers traders and users a comprehensive space to observe, track, and engage with the markets that matter most to them."
      />
      {/* Add other metadata as needed */}
    </Helmet>
    <Helmet>
      <script async src={scriptSrc} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KKLR9QGZMG');
        `}
      </script>
    </Helmet>
    <Index />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
