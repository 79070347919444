import React from "react";

const ethAddress = "0x76000C27a72b8F894B1757D74DeB6d9969D7707e";
const etherscanUrl = `https://etherscan.io/address/${ethAddress}`;

function SpecialDonation() {
  const linkStyle = {
    color: "white", // Set the link color to white
    textDecoration: "underline", // Add underline to the link
    fontWeight: "bold", // Emphasize the link with bold text
  };

  return (
    <div style={{ padding: "0 40px" }}>
      <h2>Special Donation</h2>
      <p>
        If you'd like to make a special contribution to support the development
        and maintenance of Bitference, you can make a donation using the
        following method:
      </p>
      <ul>
        <li>
          Ethereum (ETH) Address:{" "}
          <a
            href={etherscanUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            {ethAddress}
          </a>
        </li>
      </ul>
      <p>
        We greatly appreciate your support in helping us make Bitference even
        better! Special Donation contributors are considered as early backers of
        the project. As the site continues to develop and paid memberships are
        offered in the future, there may be associated benefits for special
        donors. Bitference values these special donations and may provide
        special treatment as the project evolves.
      </p>
      <p>
        To make a special donation, you can send your contribution to the
        following Ethereum address:
        <br />
        Ethereum (ETH) Address:{" "}
        <a
          href={etherscanUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          {ethAddress}
        </a>
        <br />
        Please use USDT, USDC, or DAI with a size of $250 per donation round.
        Multiple rounds of donation are accepted.
      </p>
    </div>
  );
}

export default SpecialDonation;
