import React, { useEffect } from "react";

function AdsComponent(props) {
  const { dataAdSlot } = props;

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-7163062612854276"
      data-ad-slot="4855736934"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
}

export default AdsComponent;
