import React, { useEffect } from "react";

function CoinStatsWidget({ id, sym, width, height, gridName, ticker }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.coinstats.app/widgets/coin-price-widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div style={{ transform: "scale(0.80)" }}>
      <coin-stats-ticker-widget
        style={{ borderRadius: "0" }}
        coin-id={ticker}
        locale="en"
        currency="USD"
        background="#1f2434"
        text-color="#FFFFFF"
        border-color="#1f2434"
        type="small"
        font="Roboto, Arial, Helvetica"
        height="100"
        width="100%"
      />
    </div>
  );
}

export default CoinStatsWidget;
