import { React, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import { SHA256 } from "crypto-js";
import AdsComponent from "./Ads";
import DashboardControls from "./DashboardControl";
import GridItem from "./GridItem";
import ModalComponent from "./Buy";
import { useWeb3Account } from "./Web3AccountContext"; // Adjust the path
import config from "./config";

const row0 = 30;

let tvScriptLoadingPromise;

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function loadtv() {
  if (!tvScriptLoadingPromise) {
    tvScriptLoadingPromise = new Promise((resolve) => {
      const script = document.createElement("script");
      script.id = "tradingview-widget-loading-script";
      script.src = "https://s3.tradingview.com/tv.js";
      script.type = "text/javascript";
      script.onload = resolve;

      document.head.appendChild(script);
    });
  }
}

function logDebug(message) {
  if (config.debugMode && config.logLevel === "debug") {
    console.log("[DEBUG]", message);
  }
}

function logInfo(message) {
  if (config.logLevel === "debug" || config.logLevel === "info") {
    console.log("[INFO]", message);
  }
}

function App(props) {
  const [jsonData, setJsonData] = useState([]);
  const [jsonSymbolsNames, setJsonSymbolsNames] = useState({});
  const [uuid, setUuid] = useState("");
  const [dashboardName, setDashboardName] = useState("");

  useEffect(() => {
    const storedUuid = localStorage.getItem("uuid");
    console.log("storedUuid:", storedUuid);

    let showToaster = false;
    if (!storedUuid || storedUuid === "") {
      const newUuid = uuidv4();
      localStorage.setItem("uuid", newUuid);
      setUuid(newUuid);
      showToaster = true;
    } else {
      setUuid(storedUuid);
    }
    const storedName = localStorage.getItem("name");
    console.log("storedName:", storedName);

    if (!storedName || storedName === "") {
      const newName = "New Dashboard";
      localStorage.setItem("name", newName);
      setDashboardName(newName);
      showToaster = true;
    } else {
      setDashboardName(storedName);
    }
    if (showToaster) {
      toast.success("New dashboard created!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [uuid]);

  const setUuidToNull = () => {
    handleClearAll(false);
    localStorage.setItem("uuid", "");
    localStorage.setItem("name", "");
    setUuid("");
    setDashboardName("");
  };

  // ReactGA.pageview(window.location.pathname + window.location.search);
  const containerRef = useRef(null);
  const MAX_CONTINER_NUM = 40;
  const [numContainers, setNumContainers] = useState(() => {
    const storedLayouts = localStorage.getItem("numContainers");
    console.log(`numContainers****${JSON.stringify(storedLayouts)}`);
    return storedLayouts ? JSON.parse(storedLayouts) : 0;
  });
  const [cellIndex, setCellIndex] = useState(() => {
    const storedLayouts = localStorage.getItem("cellIndex");
    return storedLayouts ? JSON.parse(storedLayouts) : 0;
  });

  const [itemConfigs, setItemConfigs] = useState(() => {
    const storedLayouts = localStorage.getItem("itemConfigs");
    return storedLayouts ? JSON.parse(storedLayouts) : {};
  });

  const [layouts, setLayouts] = useState(() => {
    const storedLayouts = localStorage.getItem("layouts");
    console.log(`layouts****${JSON.stringify(itemConfigs)}`);
    return storedLayouts
      ? JSON.parse(storedLayouts)
      : {
          lg: _.map(_.range(0, numContainers), (item, i) => {
            console.log("SHOULD BE CALLED ONCE");
            return generateChartConfig(i);
          }),
        };
  });
  const currentPathname = window.location.pathname;
  const getCurrentPathname = () => currentPathname;

  const [dashboards, setDashboards] = useState(() => {
    console.log(`getCurrentPathname: ${getCurrentPathname()}`);
    return {};
  });

  // Function to append/update entry in itemConfig
  const updateItemConfigs = (key, newValue) => {
    setItemConfigs((prevItemConfig) => ({
      ...prevItemConfig,
      [key]: newValue,
    }));
  };

  const generateHash = (obj) => {
    const objectString = JSON.stringify(obj);
    const hash = SHA256(obj).toString();
    console.log("SHA-256 Hash:", hash);
  };

  const lockCells = () => {
    const updatedLayouts = [...layouts.lg];
    const updatedItemConfigs = { ...itemConfigs };

    Object.values(updatedLayouts).forEach((layout) => {
      layout.static = true;
    });

    Object.keys(updatedItemConfigs).forEach((key) => {
      updatedItemConfigs[key].static = true;
    });

    setLayouts({ lg: updatedLayouts });
    setItemConfigs(updatedItemConfigs);
    localStorage.setItem("itemConfigs", JSON.stringify(updatedItemConfigs));
    localStorage.setItem("layouts", JSON.stringify({ lg: updatedLayouts }));
    window.location.reload();
  };

  const unlockCells = () => {
    const updatedItemConfigs = { ...itemConfigs };

    const updatedLayouts = [...layouts.lg];
    Object.values(updatedLayouts).forEach((layout) => {
      layout.static = false;
    });

    Object.keys(updatedItemConfigs).forEach((key) => {
      updatedItemConfigs[key].static = false;
    });
    setLayouts({ lg: updatedLayouts });
    setItemConfigs(updatedItemConfigs);
    localStorage.setItem("itemConfigs", JSON.stringify(updatedItemConfigs));
    localStorage.setItem("layouts", JSON.stringify({ lg: updatedLayouts }));
    window.location.reload();
  };

  const saveToServer = async (sign) => {
    const apiUrl = "https://your-api-url.com/save"; // Replace with your API URL

    try {
      // const dataToSave = dashboards;
      const accounts = ethereum.request({
        method: "eth_requestAccounts",
      });

      console.log("sig: " + JSON.stringify(sign));
      return;
      const dataToSign = JSON.stringify(layouts);
      const signature = await signData2(dataToSign);

      const payload = {
        data: dataToSaveJson,
        hash: dataHash,
        signature,
      };
      console.log("payload: ");
      console.log(payload);
      let s = signData2(payload);
      console.log("s: " + s);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data saved to server:", responseData);
        // Optionally, perform any action you want after successful save
        toast.success("Data saved to server!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        console.error("Error saving data to server:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const sendSignatureToBackend = async (signature) => {
    try {
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ signature }),
      });
      const data = await response.json();
      console.log("Backend response:", data);
    } catch (error) {
      console.error("Error sending signature to backend", error);
    }
  };

  const generateChartConfig = (i) => {
    const ret = {
      x: (i % 2) * 5,
      y: Math.floor(i / 2) * 10,
      w: 5,
      minW: 3,
      h: 10,
      minH: 5,
      i: i.toString(),
      static: false,
      name: getGridName(i),
      type: "chart",
    };

    updateItemConfigs(i.toString(), {
      name: getGridName(i),
      type: "chart",
    });

    return ret;
  };

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState({
    lg: [],
  });

  useEffect(() => {
    loadtv();
    setMounted(true);
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      console.log("Width:", rect.width);
      console.log("Height:", rect.height);
    }

    localStorage.setItem("itemConfigs", JSON.stringify(itemConfigs));
    localStorage.setItem("layouts", JSON.stringify(layouts));
    localStorage.setItem("numContainers", JSON.stringify(numContainers));
    localStorage.setItem("cellIndex", JSON.stringify(cellIndex));
    localStorage.setItem("dashboards", JSON.stringify(dashboards));

    console.log("Calling EFFECT");
  }, [layouts, dashboards]);

  /*useEffect(() => {
    // console.log("###### ####### ##### loading json data: " + jsonData);
    // Load JSON data from file
    fetch("./uniswap_tokens_sym.json")
      .then((response) =>
        // console.log('#####Response:', response); // Log the response object
        response.json(),
      )
      .then((data) => {
        console.log("##### Fetched data:", data); // Log the fetched data for debugging
        setJsonData(data);
      })
      .catch((error) => console.error("Error loading data:", error));

    // console.log("###### ####### ##### loading json data: " + jsonData);
  }, []);
  */

  useEffect(() => {
    // console.log("###### ####### ##### loading json data: " + jsonData);
    // Load JSON data from file
    fetch("./crypto_syms_ids.json")
      .then((response) => {
        console.log("#####NameResponse:", response); // Log the response object
        return response.json();
      })
      .then((data) => {
        console.log("#####NameFetched data:", data); // Log the fetched data for debugging
        setJsonSymbolsNames(data);
      })
      .catch((error) => console.error("Error loading data:", error));

    console.log(
      `###### ####### ##### loading json data symbol-name: ${jsonSymbolsNames}`,
    );
  }, []);

  const handleSaveDashboard = (dashboardName) => {
    // Check if a dashboard with the given name already exists
    const existingDashboardId = Object.keys(dashboards).find(
      (dashboardId) => dashboards[dashboardId].name === dashboardName,
    );

    if (existingDashboardId) {
      // Update the layouts and itemConfigs of the existing dashboard
      const updatedDashboard = {
        ...dashboards[existingDashboardId],
        layouts,
        itemConfigs,
      };

      setDashboards((prevDashboards) => ({
        ...prevDashboards,
        [existingDashboardId]: updatedDashboard,
      }));
    } else {
      // Create a new dashboard with a unique ID
      const newDashboard = {
        id: uuidv4(),
        name: dashboardName,
        layouts,
        itemConfigs,
      };

      setDashboards((prevDashboards) => ({
        ...prevDashboards,
        [newDashboard.id]: newDashboard,
      }));
    }
  };

  const handleAddContainer = (ticker) => {
    if (numContainers >= MAX_CONTINER_NUM) {
      toast.error("Maximum widget limit reached", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    const tickerParts = ticker.split(":");
    let tickerWithoutUSD = tickerParts[1].replace("USD", "");
    tickerWithoutUSD = tickerWithoutUSD.toLowerCase();
    // Look up jsonSymbolsNames with ticker to get full name of ticker
    const coinId = jsonSymbolsNames[tickerWithoutUSD];

    const updatedLg = [...layouts.lg];
    updatedLg[numContainers] = {
      x: (cellIndex % 2) * 5,
      y: Math.floor(cellIndex / 2) * 10,
      w: 5,
      minW: 3,
      h: 10,
      minH: 5,
      i: cellIndex.toString(),
      static: false,
      ticker,
      name: getGridName(cellIndex),
      type: "chart",
    };

    updateItemConfigs(cellIndex.toString(), {
      name: getGridName(cellIndex),
      type: "chart",
      ticker,
    });

    // setInterval(() => {updateCellConfigToItemConfig(cellIndex)
    // }, 10000);

    console.log(`itemConfig after add chart: ${JSON.stringify(itemConfigs)}`);

    setCellIndex(cellIndex + 1);
    setNumContainers(numContainers + 1);

    setLayouts({ lg: updatedLg });
    console.log(`****** AFTER ADD: ${numContainers}`);
    console.log(`****** cellIndex: ${cellIndex}`);
    console.log(layouts);
    toast.success("Added Chart", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  const handleClearAll = (showToaster) => {
    localStorage.removeItem("layouts");
    localStorage.removeItem("itemConfigs");
    localStorage.removeItem("numContainers");
    localStorage.removeItem("cellIndex");
    setItemConfigs({});
    setLayouts({ lg: [] });
    setCellIndex(0);
    setNumContainers(0);
    if (showToaster) {
      toast.success("Current dashboard cleared!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleAddTicker = (ticker) => {
    if (numContainers >= MAX_CONTINER_NUM) {
      toast.error("Maximum widget limit reached", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    const tickerParts = ticker.split(":");
    let tickerWithoutUSD = tickerParts[1].replace("USD", "");
    tickerWithoutUSD = tickerWithoutUSD.toLowerCase();
    // Look up jsonSymbolsNames with ticker to get full name of ticker
    const fullName = jsonSymbolsNames[tickerWithoutUSD];

    const updatedLg = [...layouts.lg];
    updatedLg[numContainers] = {
      x: (cellIndex % 2) * 2,
      y: Math.floor(cellIndex / 2) * 10,
      w: 2,
      h: 4,
      i: cellIndex.toString(),
      static: false,
      isResizable: false,
      name: getGridName(cellIndex),
      ticker,
      type: "ticker",
    };

    updateItemConfigs(cellIndex.toString(), {
      name: getGridName(cellIndex),
      type: "ticker",
      ticker,
    });

    console.log(`itemConfig after add ticker: ${JSON.stringify(itemConfigs)}`);

    setLayouts({ lg: updatedLg });
    setNumContainers((prevNumContainers) => prevNumContainers + 1);
    setCellIndex(cellIndex + 1);

    console.log(layouts);
    toast.success("Added Ticker", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleAddTicker2 = (ticker) => {
    console.log("handleAddTicker2 called");
    // Extract ticker part
    const tickerParts = ticker.split(":");
    let tickerWithoutUSD = tickerParts[1].replace("USD", "");
    tickerWithoutUSD = tickerWithoutUSD.toLowerCase();
    // Look up jsonSymbolsNames with ticker to get full name of ticker
    const fullName = jsonSymbolsNames[tickerWithoutUSD];

    // TODO: temp
    // fullName = tickerWithoutUSD;
    console.log(`Full name of ticker: ${tickerWithoutUSD} - ${fullName}`);
    console.log(`Full name: ${JSON.stringify(jsonSymbolsNames)}`);

    if (numContainers >= MAX_CONTINER_NUM) {
      toast.error("Maximum widget limit reached", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    const updatedLg = [...layouts.lg];
    updatedLg[numContainers] = {
      x: (cellIndex % 2) * 2,
      y: Math.floor(cellIndex / 2) * 10,
      w: 1,
      h: 4,
      i: cellIndex.toString(),
      static: false,
      isResizable: false,
      name: getGridName(cellIndex),
      ticker: tickerParts,
      type: "ticker2",
    };

    updateItemConfigs(cellIndex.toString(), {
      name: getGridName(cellIndex),
      type: "ticker2",
      ticker: tickerParts,
      symbol: tickerWithoutUSD,
      coinId: fullName,
    });

    console.log(
      `handleAddTicker2: itemConfig after add ticker: ${JSON.stringify(
        itemConfigs,
      )}`,
    );

    setLayouts({ lg: updatedLg });
    setNumContainers((prevNumContainers) => prevNumContainers + 1);
    setCellIndex(cellIndex + 1);

    console.log(layouts);
    toast.success("Added Ticker", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleAddTicker3 = (ticker) => {
    console.log("handleAddTicker3 called");
    // Extract ticker part
    const tickerParts = ticker.split(":");
    let tickerWithoutUSD = tickerParts[1].replace("USD", "");
    tickerWithoutUSD = tickerWithoutUSD.toLowerCase();
    // Look up jsonSymbolsNames with ticker to get full name of ticker
    let fullName = jsonSymbolsNames[tickerWithoutUSD];

    // TODO: temp
    fullName = tickerWithoutUSD;
    console.log(`Full name of ticker: ${tickerWithoutUSD} - ${fullName}`);
    console.log(`Full name: ${JSON.stringify(jsonSymbolsNames)}`);

    if (numContainers >= MAX_CONTINER_NUM) {
      toast.error("Maximum widget limit reached", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    const updatedLg = [...layouts.lg];
    updatedLg[numContainers] = {
      x: (cellIndex % 2) * 2,
      y: Math.floor(cellIndex / 2) * 10,
      w: 2,
      h: 2,
      i: cellIndex.toString(),
      static: false,
      isResizable: false,
      name: getGridName(cellIndex),
      ticker: tickerParts,
      type: "ticker3",
    };

    updateItemConfigs(cellIndex.toString(), {
      name: getGridName(cellIndex),
      type: "ticker3",
      ticker: tickerParts,
      symbol: tickerWithoutUSD,
      coinId: fullName,
    });

    setLayouts({ lg: updatedLg });
    setNumContainers((prevNumContainers) => prevNumContainers + 1);
    setCellIndex(cellIndex + 1);

    console.log(layouts);
    toast.success("Added Ticker", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onCompactTypeChange = () => {
    const oldCompactType = "";

    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
        ? null
        : "horizontal";
    setCompactType(compactType);
  };

  const onLayoutChange = (layout, layouts) => {
    setLayouts({ ...layouts });
  };

  const onDrop = (layout, layoutItem, _ev) => {
    console.log(
      `Element parameters:\n${JSON.stringify(
        layoutItem,
        ["x", "y", "w", "h"],
        2,
      )}`,
    );
  };

  const onRemoveItem = (n) => {
    console.log(`onRemoveItem: ${n}`);
    setLayouts({ lg: layouts.lg.filter((obj) => obj.i !== n.toString()) });
    console.log(layouts.lg.filter((obj) => obj.i !== n.toString()));
    setNumContainers((prevNumContainers) => prevNumContainers - 1);
    // localStorage.setItem("layouts", JSON.stringify(layouts));
  };

  function getGridName(i) {
    return `grid${i}`;
  }

  const getTickerTitle = (i, type) => {
    let tickerValue;
    switch (type) {
      case "ticker":
        tickerValue = itemConfigs[i].ticker;
        break;
      case "ticker2": // cs
        tickerValue = itemConfigs[i].symbol.toUpperCase();
        break;
      case "ticker3": // lcs
        tickerValue = itemConfigs[i].symbol.toUpperCase();
        break;
      default:
        tickerValue = itemConfigs[i].ticker;
    }
    return tickerValue;
  };

  const generateGridItemForTicker = (l) => {
    const tickerValue = (() => {
      switch (itemConfigs[l.i].type) {
        case "ticker":
          return itemConfigs[l.i].ticker;
        case "ticker2": // cs
          return itemConfigs[l.i].coinId;
        case "ticker3": // lcs
          return itemConfigs[l.i].symbol;
        default:
          return itemConfigs[l.i].ticker;
      }
    })();

    return (
      <GridItem
        gridName={itemConfigs[l.i].name}
        type={itemConfigs[l.i].type}
        ticker={tickerValue}
      />
    );
  };
  const removeStyleForTicker = {
    position: "absolute",
    right: "5px",
    top: 1,
    fontWeight: "bold",
    fontSize: "10px",
    transition: "background-color 0.3s", // Add a transition for background-color
  };

  const generateCloseButtonForTicker = (l, removeStyle) => (
    <>
      {l.static === false && (
        <button
          className="remove"
          style={removeStyle}
          onClick={onRemoveItem.bind(this, l.i)}
          onTouchStart={onRemoveItem.bind(this, l.i)}
        >
          Close
        </button>
      )}
    </>
  );
  const containerStyleForTicker = {
    position: "relative", // Ensure that the container is positioned relatively
    // background: "#596174",
    background: "#1f2434",
  };

  const addTicker = (i, l) => (
    <div
      id={`grid${l.i}`}
      key={l.i}
      style={containerStyleForTicker}
      className="not-resizable"
    >
      <span className="text">
        <span style={{ top: 0, fontSize: "13px", fontWeight: "bold" }}>
          &nbsp;
          {getTickerTitle(l.i, itemConfigs[l.i].type)}
        </span>
        {generateGridItemForTicker(l)}
      </span>
      {generateCloseButtonForTicker(l, removeStyleForTicker)}
    </div>
  );
  const removeStyleForChart = {
    position: "absolute",
    right: "5px",
    top: 1,
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "10px",
    transition: "background-color 0.3s", // Add a transition for background-color
  };

  const addChart = (i, l, ticker) => (
    <div
      id={`grid${l.i}`}
      key={l.i}
      style={{ background: "#1f2434" }}
      className={l.static ? "static" : ""}
    >
      <span className="text">
        <span style={{ top: 0, fontSize: "14px", fontWeight: "bold" }}>
          &nbsp;
          {itemConfigs[l.i].ticker}
        </span>

        {generateGridItemForTicker(l)}
      </span>

      {generateCloseButtonForTicker(l, removeStyleForChart)}
    </div>
  );

  return (
    <>
      <div>
        {/* Other components */}
        <ToastContainer position="bottom-right" />
      </div>

      <div
        className="droppable-element"
        draggable
        unselectable="on"
        style={{ width: "98%", margin: "25px 10px 0 10px" }}
        onDragStart={(e) => e.dataTransfer.setData("text/plain", "")}
      >
        <DashboardControls
          handleSaveDashboard={handleSaveDashboard}
          handleClearAll={handleClearAll}
          handleResetUUID={setUuidToNull}
          handleAddContainer={handleAddContainer}
          handleAddTicker={handleAddTicker}
          handleAddTicker2={handleAddTicker2}
          handleAddTicker3={handleAddTicker3}
          handleLockDashboard={lockCells}
          handleUnlockDashboard={unlockCells}
          dataSymbolsNames={jsonSymbolsNames}
          dataSymbols={jsonData}
          dataLayouts={layouts}
          dataItemConfigs={itemConfigs}
          dataUUID={uuid}
          dataDashboardName={dashboardName}
          dataCellIndex={cellIndex}
          dataNumContainers={numContainers}
          updateLayouts={setLayouts}
          updateItemConfigs={setItemConfigs}
          updateCellIndex={setCellIndex}
          updateNumContainers={setNumContainers}
          updateUUID={setUuid}
          updateDashboardName={setDashboardName}
        />
      </div>
      
      <div
        className="mb-4"
        style={{
          width: "98%",
          marginLeft: "5px",
          marginRight: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ResponsiveReactGridLayout
          {...props}
          style={{
            background: "#363740",
            width: "98%",
            margin: "15px 0px 0 0px",
          }}
          layouts={layouts}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          onDrop={onDrop}
          isDroppable
        >
          {layouts.lg.map((l, i) => {
            const itemConfig = itemConfigs[l.i];

            return itemConfig.type === "chart" ? (
              <div
                id={`grid${l.i}`}
                key={l.i}
                style={containerStyleForTicker}
                className={l.static ? "static" : ""}
              >
                <span className="text">
                  <span
                    style={{ top: 0, fontSize: "14px", fontWeight: "bold" }}
                  >
                    &nbsp;
                    {itemConfigs[l.i].ticker}
                  </span>

                  {generateGridItemForTicker(l)}
                </span>

                {generateCloseButtonForTicker(l, removeStyleForChart)}
              </div>
            ) : (
              <div
                id={`grid${l.i}`}
                key={l.i}
                style={containerStyleForTicker}
                className={`not-resizable ${l.static}` ? "static" : ""}
              >
                <div className="text">
                  <span
                    style={{
                      top: 0,
                      fontSize: "13px",
                      fontWeight: "bold",
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                    {getTickerTitle(l.i, itemConfigs[l.i].type)}
                  </span>
                  {generateGridItemForTicker(l)}
                  {generateCloseButtonForTicker(l, removeStyleForTicker)}
                </div>
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      </div>
      <div>
        <AdsComponent />
      </div>
    </>
  );
}

//<ModalComponent url="https://app.uniswap.org/#/swap?inputCurrency=0x8a3c710e41cd95799c535f22dbae371d7c858651&outputCurrency=ETH" />


export default App;

App.defaultProps = {
  className: "layout",
  rowHeight: row0,
  onLayoutChange: (layout, layouts) => {},
  cols: {
    lg: 12,
    md: 10,
    sm: 6,
    xs: 4,
    xxs: 2,
  },
  breakpoints: {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  },
  containerPadding: [0, 0],
};
