import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { SHA256 } from "crypto-js";
import { useWeb3Account } from "./Web3AccountContext";
import { ToastContainer, toast } from "react-toastify";

function MetamaskLogin({
  onLogin,
  onLogout,
  onSignRequest,
  dataLayouts,
  dataItemConfigs,
  dataUUID,
  dataCellIndex,
  dataNumContainers,
  dataAPIUrl,
  updateItemConfigs,
  updateLayouts,
  updateCellIndex,
  updateNumContainers,
  updateUUID,
  updateDashboardName,
}) {
  //const [web3, setWeb3] = useState(null);
  //const [account, setAccount] = useState(null);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const { web3, account, setWeb3State, setAccountState } = useWeb3Account();
  const [dashboardMetadata, setDashboardMetadata] = useState({});
  const [selectedUuid, setSelectedUuid] = useState("");
  const [loadedData, setLoadedData] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (account !== null) {
      loadDashboardMetadata();
    }
  }, [account]);

  useEffect(() => {
    checkMetamaskInstalled();
    checkExistingConnection();
  }, []);
  const handleLoadClick = async () => {
    if (selectedUuid) {
      console.log("Selected UUID:", selectedUuid);

      const apiUrl = dataAPIUrl + "/dashboard/" + selectedUuid; // Replace with your API URL
      try {
        const d = {
          method: "get_metadata",
        };

        const signature = await signData({ uuid: selectedUuid });
// Check if 'signature' is an object and has the property 'result'
        let finalSignature;
        if (typeof signature === 'object' && 'result' in signature) {
            finalSignature = signature.result;
        } else {
            // If 'signature' is not an object or does not have 'result', use it directly
            finalSignature = signature;
        }
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Auth-PubKey": account,
            "Auth-Signiture": finalSignature,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log("Data fetched from server:", responseData);
          const uuid = responseData["uuid"];
          const cleanedUuid = uuid.replace(/['"]+/g, "");
          const name = responseData["name"];
          const cleanedName = name.replace(/['"]+/g, "");
          updateLayouts(responseData["layouts"]);
          updateItemConfigs(responseData["configs"]);
          updateCellIndex(responseData["cellIndex"]);
          updateNumContainers(responseData["numContainers"]);
          updateUUID(uuid);
          updateDashboardName(cleanedName);
          localStorage.setItem(
            "layouts",
            JSON.stringify(responseData["layouts"]),
          );
          localStorage.setItem(
            "itemConfigs",
            JSON.stringify(responseData["configs"]),
          );
          localStorage.setItem(
            "cellIndex",
            JSON.stringify(responseData["cellIndex"]),
          );
          localStorage.setItem(
            "numContainers",
            JSON.stringify(responseData["numContainers"]),
          );
          localStorage.setItem("uuid", uuid);
          localStorage.setItem("name", cleanedName);

          // Optionally, perform any action you want after successful fetch
        } else {
          console.error(
            "Error fetching data from server:",
            response.statusText,
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };
  const checkMetamaskInstalled = () => {
    if (typeof window.ethereum !== "undefined") {
      setIsMetamaskInstalled(true);
      const web3Instance = new Web3(window.ethereum);
      setWeb3State(web3Instance);
      handleAccountChange(web3Instance);
      // Listen for account change events
      window.ethereum.on("accountsChanged", (accounts) => {
        handleAccountChange(web3Instance);
      });
    } else {
      setIsMetamaskInstalled(false);
    }
  };

  const checkExistingConnection = async () => {
    if (web3) {
      try {
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccountState(accounts[0]);
          if (onLogin !== undefined) onLogin();
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
        setAccountState(null);
      }
    }
  };

  const handleAccountChange = async (web3Instance) => {
    try {
      const accounts = await web3Instance.eth.getAccounts();
      if (accounts.length > 0) {
        setAccountState(accounts[0]);
      } else {
        setAccountState(null);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
      setAccountState(null);
    }
  };

  const handleLogin = async () => {
    if (web3) {
      try {
        // Request access to the user's accounts
        await web3.eth.requestAccounts();
        if (onLogin !== undefined) onLogin();
      } catch (error) {
        console.error("Error requesting accounts:", error);
      }
    } else {
      console.error("Web3 instance not initialized.");
    }
  };

  const signData = async (dataToSign) => {
    // Call this function when the new button is clicked
    if (web3 && account) {
      try {
        //let dataToSignHex = web3.utils.asciiToHex(dataToSign);
        //console.log(`Data to sign: ${dataToSignHex}`);
        //console.log(`Account: ${account}`);
        //web3.ethereum
        //let jsonStr = JSON.stringify({"key1": "value1"}, null, 2)
        // Sort keys of the JSON object
        function sortObjectKeys(data) {
          if (typeof data !== "object" || data === null) {
            return data;
          }
          if (Array.isArray(data)) {
            return data.map(sortObjectKeys);
          }
          return Object.keys(data)
            .sort()
            .reduce((sortedObj, key) => {
              sortedObj[key] = sortObjectKeys(data[key]);
              return sortedObj;
            }, {});
        }
        const sortedDataToSign = sortObjectKeys(dataToSign);
        dataToSign = sortedDataToSign;

        let jsonStr = JSON.stringify(dataToSign, null, 2);
        const hash = SHA256(jsonStr).toString();
        console.log("gen: " + jsonStr);
        console.log("hash: " + hash);
        console.log("dataToSign: " + JSON.stringify(dataToSign));
        // Use personal_sign instead of eth.sign
        let dataToSignHex = web3.utils.asciiToHex(hash);
        //const signature = await web3.eth.sign(dataToSignHex, account);
        // return signature;
        // Use personal_sign instead of eth.sign
        const msgParams = [account, dataToSignHex];
        const signature = await web3.currentProvider.send(
          "personal_sign",
          msgParams,
        );
        console.log(`Generated signature: ${signature}`);
        console.log("dataToSignHex: " + dataToSignHex);
        console.log("sign: " + JSON.stringify(signature));
        return signature;
      } catch (error) {
        console.error("Error signing data:", error);
      }
    } else {
      console.error("Web3 instance or account not initialized.");
    }
  };

  const handleLogout = () => {
    setAccountState(null);
    setWeb3State(null);
    if (onLogout !== undefined) onLogout();
    console.log("Metamask account logged out.");
    // const web3Instance = new Web3(window.ethereum);
    // setWeb3(web3Instance);
  };

  const loadDashboardMetadata = async () => {
    const apiUrl = dataAPIUrl + "/dashboards"; // Replace with your API URL
    try {
      const d = {
        method: "get_metadata",
      };

      //const signature = await signData({"account": account});

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Auth-PubKey": account,
          //'Auth-Signiture': signature.result,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data fetched from server:", responseData);

        setDashboardMetadata(responseData);

        // Optionally, perform any action you want after successful fetch
      } else {
        console.error("Error fetching data from server:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const [uuid, setUuid] = useState("");

  useEffect(() => {
    const storedUuid = localStorage.getItem("uuid");
    if (!storedUuid || storedUuid === "") {
      const newUuid = uuidv4();
      localStorage.setItem("uuid", newUuid);
      setUuid(newUuid);
    } else {
      setUuid(storedUuid);
    }
  }, [uuid]);

  const saveToServer2 = async (layouts, itemConfigs, name) => {
    const apiUrl = dataAPIUrl + "/dashboard"; // Replace with your API URL
    try {
      const d = {
        uuid: dataUUID,
        name: name,
        layouts: layouts,
        configs: itemConfigs,
        cellIndex: dataCellIndex,
        numContainers: dataNumContainers,
        account: account,
      };

      console.log("d: ", d);

      const signature = await signData(d);
      let finalSignature;
      if (typeof signature === 'object' && 'result' in signature) {
          finalSignature = signature.result;
      } else {
          // If 'signature' is not an object or does not have 'result', use it directly
          finalSignature = signature;
      }

      console.log("signature: " ,finalSignature);
      const payload = {
        data: d,
      };
      console.log("payload: ");
      console.log(payload);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Auth-PubKey": account,
          "Auth-Signiture": finalSignature,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data saved to server:", responseData);
        // Optionally, perform any action you want after successful save
        toast.success("Data saved to server!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        loadDashboardMetadata();
        setInputValue("");
        // setLoadedData("");
        updateDashboardName(name);
      } else {
        console.error("Error saving data to server:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 200,
    }),
    menu: (provided) => ({
      ...provided,
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "blue" : "white",
    }),
  };

  return (
    <div>
      {isMetamaskInstalled ? (
        <>
          {account ? (
            <div style={{ marginBottom: "20px", fontSize: "14px" }}>
              <div
                className="account-info"
                style={{ marginBottom: "20px", fontSize: "14px" }}
              >
                Account:
                {account}
              </div>
              <div
                className="horizontal-buttons"
                style={{ marginLeft: "20px", marginBottom: "5px" }}
              >
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  style={{ fontSize: "18px", height: "25px", width: "150px" }} // Apply bigger text style and increase height
                />
                <div className="button-spacing" />

                <button
                  className="smaller-button"
                  onClick={async () => {
                    console.log("Input Value:", inputValue); // Use inputValue as needed
                    console.log("layout latest:", JSON.stringify(dataLayouts));
                    console.log(
                      "itemConfigs:",
                      JSON.stringify(dataItemConfigs),
                    );
                    saveToServer2(dataLayouts, dataItemConfigs, inputValue); // Pass inputValue as an argument
                  }}
                >
                  Save
                </button>
              </div>
              <div
                className="horizontal-buttons"
                style={{ marginLeft: "20px" }}
              >
                <select
                  value={selectedUuid}
                  onChange={(e) => setSelectedUuid(e.target.value)}
                  style={{ fontSize: "18px", height: "25px" }}
                >
                  <option value="">Select Dashboard</option>
                  {dashboardMetadata &&
                    Object.entries(dashboardMetadata).map(([uuid, name]) => (
                      <option key={uuid} value={uuid}>
                        {name}
                      </option>
                    ))}
                </select>
                <div className="button-spacing" />
                <button className="smaller-button" onClick={handleLoadClick}>
                  Load
                </button>
              </div>
            </div>
          ) : (
            <button onClick={handleLogin} style={{ fontSize: "14px" }}>
              Connect with Metamask
            </button>
          )}
        </>
      ) : (
        <p style={{ fontSize: "13px" }}>
          Metamask not installed. Please install it to save on the remote.
        </p>
      )}
    </div>
  );
}

export default MetamaskLogin;
